import React from 'react';

import IndexLayout from 'layouts';
import TopSection from 'views/HomePage/TopSection';
import FeaturedSection from 'views/HomePage/FeaturedSection';
import BenefitsSection from 'views/HomePage/BenefitsSection';
import ContactSection from 'views/HomePage/ContactSection';
import { Components } from 'sitedata';
import LazyLoad from 'react-lazyload';

const IndexPage = () => {

  return (
    <IndexLayout>
      <LazyLoad>
        <TopSection />
      </LazyLoad>
      <LazyLoad>
        <FeaturedSection />
      </LazyLoad>
      <LazyLoad>
        <Components.FeeSection />
      </LazyLoad>
      <LazyLoad>
        <BenefitsSection />
      </LazyLoad>
      <LazyLoad height={100} scroll once>
        <ContactSection />
      </LazyLoad>
      <LazyLoad height={560} scroll once>
        <Components.Footer />
      </LazyLoad>
    </IndexLayout>
  );
};

export default IndexPage;
