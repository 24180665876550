import React, { useState } from 'react';
import { navigate } from 'gatsby';
import useLayout from 'hooks/useLayout';

import { styled } from 'styles';
import SearchAutocomplete, { AutocompleteSubmitValue } from 'components/SearchAutocomplete';
import listingStatuses from 'views/SearchPage/listingStatuses.json';

type TabKey = 'buy' | 'rent';

const SearchTabs = () => {
  const [activeTab] = useState<TabKey>('buy');
  const layout = useLayout();

  const placeholderText =
    layout === 'desktop'
      ? 'City, Neighborhood, Address, School, ZIP,  MLS #'
      : 'City, School, ZIP, Agent, MLS #';

  const handleAutocompleteSubmit = ({ suggestion, query }: AutocompleteSubmitValue) => {
    // eslint-disable-next-line no-param-reassign
    if (suggestion) delete (suggestion as any).name;
    const params: any = suggestion || {};
    if (query) params.search_string = query;
    const searchParams = new URLSearchParams(params);
    [listingStatuses.Active, listingStatuses.UnderContractOrPending].forEach(item =>
      searchParams.append('listing_status', item)
    );
    const url = `/search?${searchParams.toString()}`;
    navigate(url);
  };

  const getTabContent = (key: TabKey, placeholder) => {
    switch (key) {
      case 'buy':
      case 'rent':
        return (
          <SearchAutocomplete
            before={<span className="search__label">Search:</span>}
            placeholder={placeholder}
            onSubmit={handleAutocompleteSubmit}
          />
        );
      default:
        return null;
    }
  };
  return (
    <StyledSearchTabs className="search-tabs">
      <div className="search-tabs-content">{getTabContent(activeTab, placeholderText)}</div>
    </StyledSearchTabs>
  );
};

const StyledSearchTabs = styled.div`
  .search-tabs-content {
    padding: 16px 0 0;
  }

  .search {
    &__label {
      font-size: 14px;
      line-height: 24px;
      color: ${props => props.theme.colors.gray};
      margin: 0 6px 0 0;
    }
  }
`;

export default SearchTabs;
